import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Row, Col, message, Card, Switch, Spin, Select, Divider, DatePicker } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../comnon/wrapper';
import useTitle from '../comnon/title';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteModal from '../comnon/modal.js';
import useSettings from '../comnon/useSettings';
import AllStepsWith from './allStepsWith';
const { Option } = Select;

const OrganizationView = () => {
    const { orgId } = useParams();
    const { t } = useTranslation();
    const isEnable = useSettings();
    useTitle(`${!orgId ? t('organization.add_title') : t('organization.edit_title')}`);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAddLoading, isAddedMsg, isGetLoading, organizationData, isToggleLoading, orgStatus, isDeleteLoading, deleteStatus } = useSelector(state => state.organization);
    const { isLoading: isStreamLoading, streamList, } = useSelector(state => state.stream);

    const [checked, setChecked] = useState(false);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [deleteId, setDeleteId] = useState("");


    useEffect(() => {
        if (orgId) {
            dispatch({ type: 'ORGANIZATION_DETAIL_REQUEST', id: orgId });
        }
    }, []);



    useEffect(() => {
        if (orgId && !isGetLoading && Object.keys(organizationData).length > 0) {

        }
    }, [isGetLoading, organizationData])



    return (
        <Wrapper title={`Organization ${orgId ? "Update" : "Add"}`} >
            <div className='padding-around input-class'>
                {!isGetLoading && orgId &&
                    <div>
                        <AllStepsWith
                            orgData={organizationData}
                        />
                    </div>
                }
            </div>
        </Wrapper>
    )
};

export default OrganizationView;