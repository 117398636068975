import React from 'react';
import WelcomeCard from './welcomeCard';
import CheckList from './checkList';

const Dashboard = () => {
   return (
       <>
          <WelcomeCard />
          <CheckList />
       </>

   );

};

export default Dashboard;
