import React, { useEffect } from 'react';
const AIWidgets = () => {
    useEffect(() =>  {
        const script = document.createElement('script');
        script.src = 'https://app.dante-ai.com/bubble-embed.js?kb_id=4dfa18f7-d2d9-4362-83a9-772fc953328d&token=ce63b995-cb67-4c69-a070-e36227e0e071&modeltype=gpt-4-omnimodel-mini&tabs=false';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [])
    return (
        <>
            {/*<iframe*/}
            {/*    id="audio_iframe"*/}
            {/*    src="https://widget.synthflow.ai/widget/v2/1737910481605x682410889434810000/1737910481402x913797295194980200"*/}
            {/*    allow="microphone"*/}
            {/*    style={{*/}
            {/*        position: 'fixed',*/}
            {/*        left: '0px',*/}
            {/*        bottom: '0px',*/}
            {/*        width: '350px',*/}
            {/*        height: '600px',*/}
            {/*        background: 'transparent',*/}
            {/*        border: 'none',*/}
            {/*        zIndex: 9999,*/}
            {/*        */}
            {/*    }}*/}
            {/*></iframe>*/}
        </>
    )
}

export default AIWidgets;