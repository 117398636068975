import React from 'react';
import { Card, Image, Typography } from 'antd';
import image from '../../../assets/Screenshot.png'
import {getUserData} from "../../../utils/functions";
 

const { Title, Paragraph } = Typography;

const containerStyle = {
    display: 'flex',
    gap: '25px',
    justifyContent: 'center',
    alignItems: 'normal',
    marginBottom:'20px'
};

const cardStyle = {
    backgroundColor: '#f6f6f6',
    borderRadius: '10px',
    padding: '22px',
    boxShadow: '0 3px 8px rgba(0, 0, 0, .2)'
};

const welcomeCardStyle = {
    ...cardStyle,
    flex: 2,
};

const upcomingCardStyle = {
    ...cardStyle,
    flex: 1,
};

const textStyle = {
    fontFamily: 'Poppins,sans-serif',
    color: '#000',
    fontSize: '14px',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    marginBottom: '1em'
};

const titleStyle = {
    fontFamily: 'Poppins,sans-serif',
    fontWeight: '600',
    color: '#000',
    fontSize: '16px',
    marginBottom: '16px',
};

const subtitleStyle = {
    fontFamily: 'Poppins,sans-serif',
    fontWeight: '600',
    color: '#000',
    fontSize: '16px',
    marginBottom: '16px',
};

const upcomingDatesStyle = {
    fontFamily: 'Poppins,sans-serif',
    color: '#000',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '400',
    marginBottom: '1em'
};

const linkStyle = {
    color: '#467886',
    textDecoration: 'underline',
    fontWeight: '700',
};
const imageStyle = {
    borderRadius: '20px',
    maxWidth: '300px',
    objectFit: 'cover'
};

const WelcomeCard = () => {
    const user = getUserData();
    return (
        <div style={containerStyle}>
            <div style={welcomeCardStyle}>
                <Title level={4} style={titleStyle}>
                    Welcome, {user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)}, to the Amplify Impact Evaluation Platform!
                </Title>
                <Paragraph style={textStyle}>
                    We’re excited to have you on board as you work toward meaningful impact with your YOF grant. Please
                    review your getting started checklist below and always feel free to reach out to your CRG Impact
                    Success Manager if you have any questions.
                </Paragraph>
                <Paragraph style={textStyle}>
                    We’re here to support your success every step of the way. Let’s amplify your impact together!
                </Paragraph>
                <Paragraph style={textStyle}>
                    Contact us anytime at{' '}
                    <a href="mailto:support@crg.ca" style={linkStyle}>
                        support@crg.ca
                    </a>
                </Paragraph>
            </div>

            <div style={upcomingCardStyle}>
                <Title  style={subtitleStyle}>
                    Upcoming Key Dates:
                </Title>
                <Paragraph style={upcomingDatesStyle}>
                    January 15, 2025 – Impact evaluation onboarding webinar
                </Paragraph>
                <Paragraph style={upcomingDatesStyle}>
                    May 31, 2025 – Engage with your data webinar
                </Paragraph>
                <Paragraph style={upcomingDatesStyle}>
                    June 30, 2025 – Impact evaluations are due
                </Paragraph>
                <Paragraph style={upcomingDatesStyle}>
                    Sept. 1, 2025 – Annual Funding Reports are due
                </Paragraph>
            </div>
            {/* <Image src={image} alt="Illustration" style={imageStyle} /> */}
        </div>
    );
}
export default WelcomeCard;