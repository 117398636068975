import React, { useState } from 'react';
import { CheckCircle, Circle, ChevronDown, ChevronUp } from 'lucide-react';
import { SnippetsOutlined, BarChartOutlined } from '@ant-design/icons'
import { Card, Button, Typography } from "antd";
import BenImage from "../../../assets/ben.png";

const { Title, Text } = Typography;

const initialTodos = [
    {
        id: 1,
        title: "Review the onboarding workshop",
        description:
            "Get familiar with our onboarding process and key resources to ensure a smooth start. Our workshops are recorded and available online, please see the video below. We also host workshops, please keep an eye out for upcoming workshops you can join. You can view a list of upcoming workshops in the key dates section of our site.",
        videoUrl: "https://www.youtube.com/embed/mqQIqnNXOGI?si=hod_ayF96u9suXKw",
        completed: false,
    },
    {
        id: 2,
        title: "Get to know your Impact Success Manager",
        description: (
            <div style={{ display: "flex", alignItems: "flex-start", gap: "16px" }}>
                <img
                    src={BenImage}
                    alt="Ben Batoy"
                    style={{
                        width: "160px",
                        height: "160px",
                        borderRadius: "8px",
                        objectFit: "cover",
                    }}
                />
                <div>
                    <Text>
                        Your assigned Impact Success Manager is{" "}
                        <strong>Ben Batoy (he/him)</strong>.
                    </Text>
                    <br />
                    <br />
                    <Text>
                        Ben is an impact evaluation expert and would be happy to assist you
                        throughout your impact evaluation journey.
                    </Text>
                    <br />
                    <br />
                    <Text>
                        If you have questions about your evaluations, feel free to contact
                        Ben via email at{" "}
                        <a
                            href="mailto:bbatoy@crg.ca"
                            style={{ color: "#467886", textDecoration: "none", fontWeight: "600" }}
                        >
                            bbatoy@crg.ca
                        </a>
                        .
                    </Text>
                    <br />
                    <br />
                    <Text>
                        You can also chat with CRG’s AI Project Success Managers at any
                        time by clicking on the chat icon in the bottom right of the screen.
                    </Text>
                </div>
            </div>
        ),
        completed: false,
    },
    {
        id: 3,
        title: "Review and distribute your program evaluations",
        description: (
            <div>
                <Text>
                    The key to a successful impact evaluation is gathering meaningful data. The CRG Amplify platform makes this easy, just follow these 3 simple steps:
                </Text>
                <br />
                <br />
                <Text style={{ fontWeight: "bold", fontSize: "16px" }}>Step 1: Identify Your Evaluators</Text>
                <br />
                <Text>Decide who will participate in the evaluation.</Text>
                <br />
                <br />
                <Text style={{ fontWeight: "bold", fontSize: "16px" }}>Step 2: Choose an Invitation Method</Text>
                <br />
                <Text>
                    You can invite evaluators in multiple ways:
                    <ul style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <li>Share a URL via email, social media, or newsletters.</li>
                        <li>Use a QR code for easy scanning.</li>
                        <li>Set up an on-site computer or tablet for direct access.</li>
                        <li>Print and distribute PDF versions of the evaluation.</li>
                    </ul>
                </Text>
                <br />
                <Text style={{ fontWeight: "bold", fontSize: "16px" }}>Step 3: Access and Manage Evaluations</Text>
                <br />
                <Text>
                    You can view and manage evaluations anytime on the{" "}
                    <a
                        href="/surveys"
                        style={{
                            fontWeight: "bold",
                            color: "#467886",
                            textDecoration: "none",
                            display: "inline-flex",
                            alignItems: "center",
                            verticalAlign: "middle",
                            gap: "6px",
                        }}
                    >
                        <SnippetsOutlined style={{ fontSize: "15px", marginBottom: "3px" }} />
                        <span style={{ textDecoration: "underline" }}>Surveys</span>
                    </a>{" "}
                    page.
                </Text>
            </div>
        ),
        completed: false,
    },
    {
        id: 4,
        title: "Dive into the data and learn more about your program's impact",
        description: (
            <div>
                <Text>
                    Now that you have collected your impact evaluation data, it is time to dive into the results!
                </Text>
                <br />
                <br />
                <Text>
                    To analyze your evaluation data you can click on {" "}
                    <a
                        href="/reports"
                        style={{
                            fontWeight: "bold",
                            color: "#467886",
                            textDecoration: "none",
                            display: "inline-flex",
                            alignItems: "center",
                            verticalAlign: "middle",
                            gap: "6px",
                        }}
                    >
                        <BarChartOutlined style={{ fontSize: "15px", marginBottom: "3px" }} />
                        <span>Reports</span>
                    </a>{" "}
                    to view all available impact evaluation reports. To learn more about the reports that are available to you and how you can use them, please feel free to review the video below.
                </Text>
            </div>
        ),
        videoUrl: "https://www.youtube.com/embed/mqQIqnNXOGI?si=hod_ayF96u9suXKw",
        completed: false,
    },
];

const CheckList = () => {
    const [todos, setTodos] = useState(initialTodos);

    const toggleComplete = (id) => {
        setTodos(
            todos.map((todo) =>
                todo.id === id ? { ...todo, completed: !todo.completed } : todo
            )
        );
    };

    const [activePanel, setActivePanel] = useState(null);

    const handleToggle = (id) => {
        setActivePanel(activePanel === id ? null : id);
    };

    return (
        <div
            style={{
                padding: "20px",
                backgroundColor: "#f6f6f6",
                borderRadius: "8px",
                boxShadow: "0 3px 8px rgba(0, 0, 0, .2)",
            }}
        >
            <div
                style={{
                    maxWidth: "1200px",
                    margin: "0 auto",
                    borderRadius: "8px",
                }}
            >
                <Title
                    level={2}
                    style={{
                        textAlign: "center",
                        marginBottom: "38px",
                        fontSize: "20px",
                    }}
                >
                    Getting Started Checklist
                </Title>

                {todos.map((todo) => (
                    <Card
                        key={todo.id}
                        style={{
                            marginBottom: "24px",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        }}
                        bodyStyle={{ padding: "12px" }}
                        hoverable
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Button
                                type="text"
                                icon={
                                    todo.completed ? (
                                        <CheckCircle style={{ color: "green", fontSize: "20px" }} />
                                    ) : (
                                        <Circle style={{ color: "gray", fontSize: "20px" }} />
                                    )
                                }
                                onClick={() => toggleComplete(todo.id)}
                                style={{ marginRight: "16px" }}
                            />
                            <div style={{ flexGrow: 1 }}>
                                <Title
                                    level={4}
                                    style={{
                                        fontSize: "14px",
                                        margin: 0,
                                        textDecoration: todo.completed ? "line-through" : "none",
                                        color: todo.completed ? "gray" : "inherit",
                                    }}
                                >
                                    {todo.title}
                                </Title>
                            </div>
                            <Button
                                type="text"
                                icon={
                                    activePanel === todo.id ? (
                                        <ChevronUp
                                            style={{
                                                fontSize: "20px",
                                                color: "#CBD5E1",
                                                background: "#FFF",
                                                borderColor: "none",
                                            }}
                                        />
                                    ) : (
                                        <ChevronDown
                                            style={{
                                                fontSize: "20px",
                                                color: "#CBD5E1",
                                                background: "#FFF",
                                                borderColor: "none",
                                            }}
                                        />
                                    )
                                }
                                onClick={() => handleToggle(todo.id)}
                                style={{ marginTop: "8px" }}
                            />
                        </div>

                        {activePanel === todo.id && (
                            <div
                                style={{
                                    margin: "12px auto",
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: "14px",
                                        marginBottom: "20px",
                                        marginTop: "10px",
                                    }}
                                >
                                    {todo.description}
                                </Text>

                                {todo.videoUrl && (
                                <div  style={{maxWidth: "800px", margin: "auto"}}>
                                    <iframe
                                        title="video"
                                        width="100%"
                                        height="450"
                                        src={todo.videoUrl}
                                        frameBorder="0"
                                        allow="autoplay; encrypted-media; picture-in-picture"
                                        allowFullScreen
                                        style={{
                                            borderRadius: "8px",
                                            marginTop: "10px",
                                            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                                        }}
                                    ></iframe>
                                </div>
                                )}
                            </div>
                        )}
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default CheckList;