/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Row, Col, Steps, Select, Card, DatePicker, Switch, Spin, message, InputNumber } from 'antd';

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import { RightOutlined, LeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import isNumeric from "antd/es/_util/isNumeric";

const { Option } = Select;
const { TextArea } = Input;

let tmpFormValues = {};
let tmpFormValuesArray = [];
let isSignShowFor = {
    'incorporation_level': false,
    'incorporation_no': false
}
const AllStepsWith = ({ orgData }) => {

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const { t, i18n } = useTranslation();
    const { orgId } = useParams();
    const dispatch = useDispatch();
    const { isGetLoading, organizationData, isStepLoading, stepData, isStepUpdating, updateStepMsg, step2Data, redirectTo, isOrgExportLoading, orgExportData } = useSelector(state => state.organization);

    const [currentStep, setCurrentStep] = useState(1);
    const [loadHtml, setLoadHtml] = useState(1);
    const [groupExportLoading, setIsGroupExportLoading] = useState(isOrgExportLoading);
    const [tmpOrgExportData, setTmpOrgExportData] = useState("");
    const [numberOfProgram, setNumberOfPrgoram] = useState("");


    const handleProgramEvaluatedChange = () => {
        console.log('coming in program funding change')
        const element = document.getElementById('basic_number_of_programs_evaluated');

        if (!element) {
            return;
        }
        const numberOfPrograms= element.getAttribute('aria-valuenow');

        let totalSum = 0;
        for (let i = 0; i < numberOfPrograms; i++) {
            // Construct the ID of the input element based on the loop counter
            const inputId = `basic_programs_detail_${i}_amount_of_funding`;
            const currentInput = document.getElementById(inputId);
            if (currentInput) {
                const currentValue = parseInt(currentInput.value, 10);
                if (isNumeric(currentValue)) {
                    totalSum += currentValue;
                }
            }
        }

        const sumElement = document.getElementById('total-funding-sum');  // Assuming you have a 'p' element with this ID
        if (sumElement) {
            sumElement.textContent = `Total amount of funding entered for all programs is ${totalSum}`;
        }


    };


    const stepsData = [
        {
            title: t("org_detail.general_info"),
            subTitle: t("org_detail.general_desc"),
            key: "general-info",
            id: 1,
            icon: "GI"
        },
        {
            title: t("org_detail.primary_info"),
            subTitle: t("org_detail.primary_desc"),
            key: "primary-info",
            id: 2,
            icon: "PC"
        },
        {
            title: t("org_detail.spec_info"),
            subTitle: t("org_detail.spec_desc"),
            key: "specific-info",
            id: 3,
            icon: "SI"
        },
        {
            title: t("org_detail.internal_info"),
            subTitle: t("org_detail.internal_desc"),
            key: "internal-info",
            id: 4,
            icon: "IC"
        },
        {
            title: t("org_detail.funding_info"),
            subTitle: t("org_detail.funding_desc"),
            key: "funding-info",
            id: 5,
            icon: "FI"
        }
    ];

    const step1FormData = [
        {
            label: t("org_detail.agency_name"),
            name: 'name',
            isRequired: false,
            asterisksSign: true,
            fieldType: "text",
            readOnly: true,
            max: 100,
            col: 12
        },
        {
            label: t("org_detail.legal_name"),
            name: 'legal_name',
            isRequired: false,
            asterisksSign: true,
            fieldType: "text",
            max: 100,
            col: 12
        },
        {
            label: t("org_detail.date_established"),
            name: 'date_established',
            isRequired: false,
            asterisksSign: true,
            fieldType: "datePicker",
            max: "",
            disabled: 'past',
            col: 12
        },
        {
            label: "",
            name: 'blank'
        },
        {
            label: t("org_detail.incorporated"),
            name: 'is_incorporated',
            isRequired: false,
            fieldType: "toggle",
            defaultValue: 0,
            col: 24
        },
        {
            label: t("org_detail.incorporation_level"),
            name: 'incorporation_level',
            isRequired: false,
            fieldType: "select",
            col: 12,
            defaultValue: 1,
            options: [
                {
                    label: 'Federal',
                    value: 1
                },
                {
                    label: 'Provincial',
                    value: 2
                },

            ]
        },
        {
            label: t("org_detail.incorporation_no"),
            name: 'incorporation_no',
            isRequired: false,
            fieldType: "number",
            col: 12
        },
        {
            label: t("org_detail.charitable_no"),
            name: 'charitable_no',
            isRequired: false,
            fieldType: "text",
            col: 12
        },
        {
            label: "",
            name: 'blank'
        },
        {
            label: t("org_detail.address"),
            name: 'address',
            isRequired: false,
            asterisksSign: true,
            fieldType: "text",
            max: 500,
            col: 12
        },
        {
            label: t("org_detail.email"),
            name: 'email',
            isRequired: false,
            asterisksSign: true,
            fieldType: "email",
            max: 225,
            col: 12
        },
        {
            label: t("org_detail.phone_number"),
            name: 'phone_number',
            isRequired: false,
            asterisksSign: true,
            fieldType: "text",
            max: 25,
            col: 12
        },
        {
            label: t("org_detail.instagram"),
            name: 'instagram',
            isRequired: false,
            fieldType: "url",
            placeholder: "https://www.instagram.com/",
            col: 12
        },
        {
            label: t("org_detail.facebook"),
            name: 'facebook',
            isRequired: false,
            fieldType: "url",
            placeholder: "https://facebook.com/",
            col: 12
        },
        {
            label: t("org_detail.twitter"),
            name: 'twitter',
            isRequired: false,
            fieldType: "url",
            placeholder: "https://twitter.com/",
            col: 12
        },
        {
            label: t("org_detail.website"),
            name: 'website',
            isRequired: false,
            fieldType: "url",
            placeholder: "example.com",
            col: 12,
        }
    ];

    const step2FormData = [
        {
            label: t("org_detail.first_name"),
            name: 'first_name',
            isRequired: false,
            asterisksSign: true,
            fieldType: "text",
            max: 100,
            col: 12
        },
        {
            label: t("org_detail.last_name"),
            name: 'last_name',
            isRequired: false,
            asterisksSign: true,
            fieldType: "text",
            max: 100,
            col: 12
        },
        {
            label: t("org_detail.email"),
            name: 'email',
            isRequired: false,
            asterisksSign: true,
            fieldType: "email",
            max: 225,
            col: 12
        },
        {
            label: t("org_detail.phone_number"),
            name: 'phone_number',
            isRequired: false,
            asterisksSign: true,
            fieldType: "text",
            col: 12
        },
        {
            label: t("org_detail.signing_authority"),
            name: 'is_signing_authority',
            isRequired: false,
            fieldType: "toggle",
            defaultValue: 0,
            col: 12
        },
        {
            label: t("org_detail.job_title"),
            name: 'job_title',
            isRequired: false,
            asterisksSign: true,
            fieldType: "text",
            col: 12
        },

    ];

    useEffect(() => {
        tmpFormValues = {};
        tmpFormValuesArray = [];
    }, [])
    useEffect(() => {
        if (currentStep === 2) {
            if (!isStepLoading && Array.isArray(step2Data) && step2Data.length > 0) {
                tmpFormValuesArray = step2Data;
                step2Data.map((tmpNameCreate, index) => {
                    for (const key in tmpNameCreate) {
                        if (tmpNameCreate[key] !== null && tmpNameCreate[key] !== "") {
                            let tmpName = ['contact', index, key];
                            // form.setFieldsValue({ [`${index}_${key}`]: tmpNameCreate[key] });
                            form.setFieldsValue({ [`${tmpName}`]: tmpNameCreate[key] });
                        }
                    }
                });
                loadStep2Field(step2FormData);
            } else if (!isStepLoading && Array.isArray(step2Data) && step2Data.length === 0) {
                loadStep2Field(step2FormData);
            }
        } else if (currentStep === 1) {
            if (!isStepLoading && Object.keys(stepData).length > 0) {
                tmpFormValues = {};
                for (const key in stepData) {
                    if (stepData[key] !== null && stepData[key] !== "") {
                        // form.setFieldValue(stepData);
                        if (key === "date_established") {
                            form.setFieldsValue({ [key]: moment(stepData[key]) });
                            tmpFormValues[key] = moment(stepData[key]);
                        } else {
                            form.setFieldsValue({ [key]: stepData[key] });
                            tmpFormValues[key] = stepData[key];
                        }
                    }
                }
                loadFields(step1FormData);
            } else if (!isStepLoading && Object.keys(stepData).length === 0) {

                loadFields(step1FormData);
            }
        }

    }, [isStepLoading, stepData, step2Data, currentStep, i18n.language])

    useEffect(() => {
        if (!isStepUpdating && updateStepMsg === "success") {
            if (redirectTo !== "" && (redirectTo !== 5000 && redirectTo !== "5000")) {
                changeStep(parseInt(redirectTo));
            } else if (redirectTo === 5000 || redirectTo === "5000") {
                if (currentStep === 5) {
                    loadStepsData(5);
                }
            } else {
                changeStep(currentStep + 1);
            }
            dispatch({ type: "ORG_STEP_CLEAR" })
            message.success(t('org_detail.info_save'))
        } else if (!isStepUpdating && updateStepMsg?.errors) {
            let msg = updateStepMsg?.message;
            message.error({ content: msg || "Something went wrong", className: 'error-class', duration: 3 });
        } else if (!isStepUpdating && updateStepMsg?.message) {
            message.error({ content: updateStepMsg?.message, className: 'error-class', duration: 3 });
        }
    }, [isStepUpdating, updateStepMsg, redirectTo]);


    useEffect(() => {
        if (currentStep === 1) {
            loadStepsData(1);
        }
    }, []);

    const loadStepsData = (stepId) => {
        dispatch({
            type: 'ORGANIZATION_STEP_REQUEST',
            step: stepId,
            orgId: orgId
        });
    }

    const onFinish = (values) => {
        submitFromOutside(values);
    }

    const submitForm = (stepIdFromBtn) => {
        form.validateFields()
            .then((values) => {
                submitFromOutside(values, stepIdFromBtn);
            })
            .catch((errorInfo) => {

            });
    }
    const submitFromOutside = (values, redirectTo = "") => {
        let tmpFormData = {};
        if (currentStep === 2) {

            let tmpData = [...values.contact];
            if (tmpData?.length > 0) {
                let filterData = tmpData?.filter(e => e.is_signing_authority);
                if (filterData.length < 1) {
                    message.error(t('org_detail.select_one'));
                    return;
                }
                tmpFormData = tmpData.map((e) => {
                    return { ...e, is_signing_authority: e.is_signing_authority ? 1 : 0 };
                })
            }
        } else if (currentStep === 3) {

            for (const key in values) {
                if (values[key] !== undefined) {
                    if (key === "agm_date") {
                        if (values['agm_date']) {
                            tmpFormData[key] = moment(values['agm_date']).format(`YYYY-MM-DD`);
                        } else {
                            tmpFormData[key] = "";
                        }
                    } else if (key === "most_recent_law_approval_date") {
                        if (values['most_recent_law_approval_date']) {
                            tmpFormData[key] = moment(values['most_recent_law_approval_date']).format(`YYYY-MM-DD`);
                        } else {
                            tmpFormData[key] = "";
                        }
                    } else {
                        tmpFormData[key] = values[key];
                    }
                } else {
                    tmpFormData[key] = "";
                }
            }
        } else if (currentStep === 4) {
            let tmpQuotation = [];
            values.questions !== undefined && values.questions.map((item) => {
                if (item) {
                    tmpQuotation.push({
                        question_id: item.question_id,
                        answer: item.answer
                    })
                }
            });
            tmpFormData = tmpQuotation;
        } else if (currentStep === 5) {
            tmpFormData = values;
        } else {
            // return;
            for (const key in values) {
                if (values[key] !== undefined) {
                    if (key === "date_established") {
                        tmpFormData[key] = moment(values['date_established']).format(`YYYY-MM-DD`);
                    } else if (key === "is_incorporated") {
                        tmpFormData[key] = values[key] ? 1 : 0;
                    } else {
                        tmpFormData[key] = values[key];
                    }
                } else {
                    tmpFormData[key] = "";
                }
            }
        }


        dispatch({
            type: 'ORGANIZATION_STEP_POST_REQUEST',
            step: currentStep,
            orgId: orgId,
            formData: tmpFormData,
            redirectTo: redirectTo
        });


    }
    const onFinishFailed = (values) => { }

    const loadFields = (totalData) => {
        let tmpHtml = [];
        setLoadHtml("");
        if (totalData.length > 0) {
            totalData.map((data, index) => {
                let html = "";
                if (data?.name !== "blank") {
                    html = (<Col xs={24} sm={24} md={data?.col || 12} lg={data?.col || 12} xl={data?.col || 12} className="red" key={index}>
                        {getFieldType(data)}
                    </Col>)
                } else if (data?.name === "blank") {
                    html = (
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="red" key={index}>
                        </Col>
                    );
                }

                tmpHtml.push(html);
            })
        }
        // return tmpHtml;
        setLoadHtml(tmpHtml);
    }


    const loadStep2Field = (totalData, stepNo) => {

        let tmpHtml = [];
        setLoadHtml("");
        [0, 1, 2].map((mainData, mainIndex) => {
            if (totalData.length > 0) {

                totalData.map((data, index) => {

                    let tmpData = { ...data };
                    if (currentStep === 2) {
                        if (mainIndex === 0) {
                            if (tmpData?.name === "job_title") {
                                tmpData.isRequired = false;
                            } else {
                                tmpData.isRequired = true;
                            }
                        } else {
                            tmpData.isRequired = false;
                            tmpData.asterisksSign = false;
                        }
                    }


                    tmpData.originalName = data.name;
                    let html = "";
                    if (tmpData?.name !== "blank") {
                        html = (<Col xs={24} sm={24} md={tmpData?.col || 12} lg={tmpData?.col || 12} xl={tmpData?.col || 12} className="red" key={index}>
                            {getFieldType(tmpData, mainIndex)}
                        </Col>)
                    } else if (data?.name === "blank") {
                        html = (
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="red" key={index}>
                            </Col>
                        );
                    }

                    tmpHtml.push(html);
                });

                if (mainIndex === 0 || mainIndex === 1) {
                    let thtml = (<Col xs={24} sm={24} md={24} lg={24} xl={24} key={"section" + mainIndex}>
                        <div className="border-bottom"></div>
                    </Col>);
                    tmpHtml.push(thtml);
                }
            }

        })
        setLoadHtml(tmpHtml);
        // return tmpHtml;
    }

    const triggerChangeEvent = (isChecked, data) => {
        if (currentStep === 1) {
            isSignShowFor = {
                'incorporation_level': isChecked,
                'incorporation_no': isChecked
            }
            loadFields(step1FormData);
        }

    }

    const getFieldType = (data, mainIndex = "") => {
        let rules = [];
        let tmpName = data.name;
        let tmpInitialValues = "";
        if (mainIndex !== "" && currentStep === 2) {
            tmpName = ['contact', mainIndex, tmpName];
            if (tmpFormValuesArray.length > 0) {
                let tmpOrgName = data?.originalName;

                if (tmpFormValuesArray[mainIndex] !== undefined && tmpFormValuesArray[mainIndex] !== null) {
                    tmpInitialValues = tmpFormValuesArray[mainIndex][tmpOrgName] !== undefined ? tmpFormValuesArray[mainIndex][tmpOrgName] : "";
                }
            }
        } else {

            tmpInitialValues = tmpFormValues[data.name] !== undefined ? tmpFormValues[data.name] : "";
        }
        if (data?.isRequired) {
            rules.push({
                required: data.isRequired,
                message: t('org_detail.field_is_required')
            })
        }
        if (data?.fieldType === "email") {
            rules.push({
                type: 'email',
                message: t('org_detail.enter_valid_email')
            })
        }
        let tmpSign = data?.asterisksSign || false;
        if (data?.name === "incorporation_level") {
            tmpSign = isSignShowFor.incorporation_level || false;
        } else if (data?.name === "incorporation_no") {
            tmpSign = isSignShowFor.incorporation_no || false;
        }
        const className = tmpSign ? 'form-field asteriks-sign' : 'form-field ';


        if (data?.fieldType === "text" || data?.fieldType === "email") {
            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className={className}
                    rules={rules}
                    initialValue={tmpInitialValues}
                >
                    <Input
                        defaultValue={tmpInitialValues}
                        readOnly={data?.readOnly || false}
                        disabled={data?.readOnly || false}
                        type={data?.fieldType}
                        placeholder={data?.placeholder} />
                </Form.Item>
            );
        } else if (data?.fieldType === "textarea") {
            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className={className}
                    rules={rules}
                    initialValue={tmpInitialValues}
                >
                    <TextArea
                        defaultValue={tmpInitialValues}
                        readOnly={data?.readOnly || false}
                        disabled={data?.readOnly || false}
                        type={data?.fieldType}
                        row={3}
                        placeholder={data?.placeholder} />
                </Form.Item>
            );
        } else if (data?.fieldType === "datePicker") {
            return (<Form.Item
                    label={data.label}
                    name={tmpName}
                    className={className}
                    rules={rules}
                >
                    <DatePicker
                        disabledDate={(e) => {
                            if (data?.disabled === "past") {
                                return e >= moment().endOf('day');
                            } else if (data?.disabled === "future") {
                                return e <= moment().endOf('day');
                            }
                        }}
                        size='large' placeholder={data?.placeholder} />
                </Form.Item>
            );
        } else if (data?.fieldType === "select") {
            return (<Form.Item
                    label={data.label}
                    name={tmpName}
                    className={className}
                    rules={rules}
                >
                    <Select
                        optionFilterProp="children"
                        autoComplete="none"
                        size="large"
                    >

                        {data?.options && data?.options?.length > 0 && data?.options.map((item) =>
                            <Option key={item.value} value={item.value}>{item.label}</Option>
                        )}
                    </Select>
                </Form.Item>
            );
        } else if (data?.fieldType === "number") {
            rules.push({
                pattern: new RegExp(/^[0-9]*$/),
                message: t('org_detail.Please_enter_valid_number'),
            });
            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className={className}
                    rules={rules}
                    initialValue={tmpInitialValues}
                >
                    <Input type={"number"} defaultValue={tmpInitialValues} placeholder={data?.placeholder} />
                </Form.Item>
            );
        }
        else if (data?.fieldType === "url") {
            rules.push({
                type: "url",
                message: t('org_detail.enter_valid_url'),
            });

            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className={className}
                    rules={rules}
                    getValueFromEvent={(e) => {
                        const { value } = e.target;
                        if (value && !value.startsWith("http://") && !value.startsWith("https://")) {
                            return "https://" + value;
                        }
                        return value;
                    }}
                >
                    <Input placeholder={data?.placeholder} />
                </Form.Item>
            );
        } else if (data?.fieldType === "toggle") {
            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className={className}
                    rules={rules}
                    initialValue={(tmpInitialValues === 0 || tmpInitialValues === "") ? false : true}
                >
                    <Switch
                        onChange={(e) => triggerChangeEvent(e, data)}
                        defaultChecked={(tmpInitialValues === 0 || tmpInitialValues === "") ? false : true}
                        checkedChildren={<span>{t('common.yes')}</span>}
                        unCheckedChildren={<span>{t('common.no')}</span>}
                        size='large'
                    />

                </Form.Item>
            );
        } else if (data?.fieldType === "hidden") {
            return (
                <Form.Item
                    style={{ display: 'none' }}
                    label={data.label}
                    name={data.name}
                    className={className}
                    initialValue={data.defaultValue}
                >
                    <Input defaultValue={data.defaultValue} />
                </Form.Item>
            );
        }
    }

    const changeStep = (selectStep) => {
        setLoadHtml("");
        setCurrentStep(selectStep);
        loadStepsData(selectStep);
    }

    const getFieldTypeCommon = (data, existingData, mainIndex = "") => {

        let rules = [];
        let tmpName = data.name;
        let tmpInitialValues = "";
        if (mainIndex !== "" && currentStep === 2) {
            tmpName = ['contact', mainIndex, tmpName];
            if (existingData.length > 0) {
                let tmpOrgName = data?.originalName;
                tmpInitialValues = existingData[mainIndex][tmpOrgName] !== undefined ? existingData[mainIndex][tmpOrgName] : "";
            }
        } else if (mainIndex !== "" && currentStep === 4) {
            tmpName = ['questions', mainIndex, tmpName];
            tmpInitialValues = data?.defaultValue || "";
            rules.push({
                validator: (rule, value, callback) => {

                    if (value) {
                        value = parseInt(value);
                        if (value < data?.min || value > data?.max) {
                            callback(t("common.between_error").replace("{x}", data?.min).replace("{y}",data?.max));
                        } else {
                            callback();
                        }
                    } else {
                        callback();
                    }
                    return;
                }
            })

        } else if (currentStep === 5) {
            if (data.name === "funding_streams" || data.name === "total_funding_amount" || data.name === "number_of_programs_evaluated") {
                tmpInitialValues = existingData[data.name] !== undefined ? existingData[data.name] : "";
            } else {
                tmpName = ['programs_detail', mainIndex, tmpName];
                // tmpInitialValues = data?.defaultValue || "";
                if (data.name === "additional_target_population" || data.name === "additional_wards" || data.name === "primary_target_population" || data.name === "primary_wards") {
                    if (existingData[mainIndex] !== undefined) {
                        tmpInitialValues = existingData[0]?.[data.name] !== null && existingData[0]?.[data.name] !== undefined && existingData[0]?.[data.name].length > 0 ? existingData[0]?.[data.name].map(e => e.id) : [];
                    } else {
                        tmpInitialValues = [];
                    }
                } else {
                    if (existingData) {
                        if (existingData[mainIndex] !== undefined) {
                            tmpInitialValues = existingData[mainIndex][data.name] !== undefined ? existingData[mainIndex][data.name] : "";
                        } else {
                            tmpInitialValues = "";
                        }
                    }
                }
            }
        } else {
            tmpInitialValues = existingData[data.name] !== undefined ? existingData[data.name] : "";
        }

        let tmpAddBefore = {};
        if (data?.prefix) {
            tmpAddBefore = { addonBefore: data?.prefix };
        }


        if (data?.isRequired) {
            rules.push({
                required: data.isRequired,
                message: "Field is required"
            })
        }
        if (data?.fieldType === "email") {
            rules.push({
                type: 'email',
                message: "Enter valid email",
            })
        }
        if (data?.fieldType === "text" || data?.fieldType === "email") {
            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className={`form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                    rules={rules}
                    initialValue={tmpInitialValues}
                >
                    <Input
                        {...tmpAddBefore}
                        // defaultValue={tmpInitialValues}
                        readOnly={data?.readOnly || false}
                        disabled={data?.readOnly || false}
                        type={data?.fieldType}
                        placeholder={data?.placeholder} />
                </Form.Item>
            );
        } else if (data?.fieldType === "textarea") {
            if (data?.maxWord) {
                rules.push({
                    validator: (rule, value, callback) => {
                        if (value || value !== "") {
                            if (value.split(" ").length - 1 > data?.maxWord) {
                                callback(t("common.max_with_error").replace("{x}", data?.maxWord));
                            } else if (value.split(" ").length - 1 <= data?.maxWord) {
                                callback();
                            }
                        } else {
                            callback();
                            return true;
                        }
                        return;
                    }
                })
            }


            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className='form-field textarea-field'
                    rules={rules}
                    initialValue={tmpInitialValues}
                >
                    <TextArea
                        defaultValue={tmpInitialValues}
                        readOnly={data?.readOnly || false}
                        disabled={data?.readOnly || false}
                        rows={5}
                        placeholder={data?.placeholder} />
                </Form.Item>
            );
        } else if (data?.fieldType === "datePicker") {

            return (<Form.Item
                    label={data.label}
                    name={tmpName}
                    className={`form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                    rules={rules}
                    initialValue={tmpInitialValues}
                >
                    <DatePicker
                        disabledDate={(e) => {
                            if (data?.disabled === "past") {
                                return e >= moment().endOf('day');
                            } else if (data?.disabled === "future") {
                                return e <= moment().endOf('day');
                            }
                        }}
                        size='large'
                        placeholder={data?.placeholder}
                    />
                </Form.Item>
            );
        } else if (data?.fieldType === "select") {
            if (data?.max) {
                rules.push({
                    validator: (rule, value, callback) => {
                        if (value) {
                            if (value.length > data?.max) {
                                callback(`Please select only ${data?.max} option`);
                            } else if (value.length <= data?.max) {
                                callback();
                            }
                        } else {
                            callback('');
                        }
                        return;
                    }
                })
            }

            return (<Form.Item
                    label={data.label}
                    name={tmpName}
                    className={`form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                    rules={rules}
                    // initialValue={tmpInitialValues || data?.mode === "multi" ? [] : ""}
                    initialValue={tmpInitialValues}
                >
                    <Select
                        optionFilterProp="children"
                        autoComplete="none"
                        size="large"
                        mode={data?.mode === "multi" ? "multiple" : "single"}
                    >

                        {data?.options && data?.options?.length > 0 && data?.options.map((item) =>
                            <Option key={item.value} value={item.value}>{item.label}</Option>
                        )}
                    </Select>
                </Form.Item>
            );
        } else if (data?.fieldType === "number") {

            if (data?.name === "amount_of_funding") {
                return (
                    <Form.Item
                        label={data.label}
                        name={tmpName}
                        id={tmpName}
                        className={`form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                        rules={rules}
                        initialValue={tmpInitialValues}
                    >
                        <Input
                            {...tmpAddBefore}
                            type={"number"}
                            placeholder={data?.placeholder}
                            onChange={handleProgramEvaluatedChange}
                        />
                    </Form.Item>
                );
            }

            if (data?.name === "total_funding_amount") {
                return (
                    <Form.Item
                        label={data.label}
                        name={tmpName}
                        id={tmpName}
                        className={`form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                        rules={rules}
                        initialValue={tmpInitialValues}
                    >
                        <Input
                            {...tmpAddBefore}
                            type={"number"}
                            placeholder={data?.placeholder}
                        />
                        <p id='total-funding-sum'></p>
                    </Form.Item>
                );
            }

            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    id={tmpName}
                    className={`form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                    rules={rules}
                    initialValue={tmpInitialValues}
                >
                    <Input
                        {...tmpAddBefore}
                        type={"number"}
                        placeholder={data?.placeholder}
                    />
                </Form.Item>
            );
        } else if (data?.fieldType === "inputnumber") {

            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className={`input-number form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                    rules={rules}
                    initialValue={tmpInitialValues}
                >
                    <InputNumber
                        {...tmpAddBefore}
                        type={"number"}
                        min={data?.min}
                        placeholder={data?.placeholder}
                        onChange={(e) => setNumberOfPrgoram(e)}
                    />
                </Form.Item>
            );
        } else if (data?.fieldType === "url") {
            rules.push({
                type: "url",
                message: "Enter valid URL"
            });

            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className={`form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                    rules={rules}
                    initialValue={tmpInitialValues}
                >
                    <Input placeholder={data?.placeholder} />
                </Form.Item>
            );
        } else if (data?.fieldType === "toggle") {
            return (
                <Form.Item
                    label={data.label}
                    name={tmpName}
                    className={`form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                    rules={rules}
                    initialValue={(tmpInitialValues === 0 || tmpInitialValues === "") ? false : true}
                >
                    <Switch
                        defaultChecked={(tmpInitialValues === 0 || tmpInitialValues === "") ? false : true}
                        checkedChildren={<span>{t('common.yes')}</span>}
                        unCheckedChildren={<span>{t('common.no')}</span>}
                        size='large'
                    />

                </Form.Item>
            );
        } else if (data?.fieldType === "hidden") {
            return (
                <Form.Item
                    style={{ display: 'none' }}
                    label={data.label}
                    name={tmpName}
                    className={`form-field ${data?.asterisksSign ? 'asteriks-sign' : ''}`}
                    initialValue={data.defaultValue}
                >
                    <Input defaultValue={data.defaultValue} />
                </Form.Item>
            );
        }
    }

    const exportOrgDetail = () => {
        setIsGroupExportLoading(true);
        dispatch({ type: 'ORGANIZATION_EXPORT_REQUEST', orgId: orgId });
    }

    // useEffect(() => {
    //     if (!isOrgExportLoading && orgExportData !== "") {
    //         setTmpOrgExportData(orgExportData);
    //         setTimeout(() => {
    //             dispatch({ type: "CLEAR_REPORT_STATUS" });
    //             setIsGroupExportLoading(isOrgExportLoading);
    //             document.getElementById('exportToCSVLink').click();
    //         }, 1000);
    //     }
    // }, [isOrgExportLoading, orgExportData]);



    return (
        <div className='step-parent'>
            {/* <div className='export-org'>
                <Button id="export-group"
                    className='export-btn'
                    type="primary"
                    shape="circle"
                    // loading={groupExportLoading}
                    // disabled={groupExportLoading}
                    onClick={() => exportOrgDetail()}
                    icon={<DownloadOutlined />} size={"medium"} />
                <label htmlFor="export-group" className="nav-add-label">&nbsp;Export</label>
                <CSVLink id="exportToCSVLink" filename="Organization detail.csv" data={tmpOrgExportData} />
            </div> */}
            <Row>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} className="red">
                    <div className="step-container">
                        {stepsData.map((item) => {
                            return (
                                <div className={`step ${currentStep === item?.id ? 'active' : ''}`} key={item?.id}>
                                    <div className="content">
                                        <p className='title'>{item?.title}</p>
                                        <p className='sub-title'>{item?.subTitle}</p>
                                    </div>
                                    <div className="v-stepper">
                                        {/* <div className="circle" onClick={() => changeStep(item.id)}>{item?.icon}</div> */}
                                        <div className="circle" onClick={() => submitForm(item.id)}>{item?.icon}</div>
                                        <div className="line"></div>
                                    </div>
                                </div>

                            )
                        })}
                    </div>
                </Col>
                <Col xs={24} sm={24} md={18} lg={18} xl={18} className="left">


                    <Form
                        className='sign-form'
                        name='basic'
                        onFinish={onFinish}
                        layout="vertical"
                        form={form}
                        requiredMark={false}
                        onFinishFailed={onFinishFailed}
                    >
                        <Card className='archive-card'>
                            <p>{t("org_detail.please_information")}</p>
                            <>
                                {(isGetLoading || isStepLoading) && <Spin />}
                                {!isGetLoading && !isStepLoading &&
                                    <Row gutter={70}>

                                        {(currentStep === 1 || currentStep === 2) && loadHtml}
                                        {currentStep === 3 &&
                                            <Step3
                                                getFieldType={(data, existingData) => getFieldTypeCommon(data, existingData)}
                                                step={3}
                                            />
                                        }
                                        {currentStep === 4 &&
                                            <Step4
                                                getFieldType={(data, existingData, qid) => getFieldTypeCommon(data, existingData, qid)}
                                                step={4}
                                            />
                                        }
                                        {currentStep === 5 &&
                                            <Step5
                                                getFieldType={(data, existingData, qid) => getFieldTypeCommon(data, existingData, qid)}
                                                step={5}
                                                setNumberOfProgram={numberOfProgram}
                                                handleProgramEvaluatedChange={handleProgramEvaluatedChange}
                                            />
                                        }
                                    </Row>
                                }

                                <div className='bottom-footer'>
                                    <p>{t("common.page_of").replace("{x}", currentStep)}</p>
                                </div>
                            </>
                        </Card>
                        <Row gutter={70} className={"mt10"}>
                            {/* <Col xs={{ span: 24, offset: 3 }} md={{ span: 24, offset: 6 }} lg={{ span: 12, offset: 9 }} > */}
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="prev-nextbtn">
                                {/* <Button
                                    type="secondary"
                                    onClick={() => {
                                        setCurrentStep(currentStep - 1)
                                    }}
                                    className={""} shape="round" size={"large"}>
                                    Back
                                </Button> */}
                                {/* {currentStep !== 1 && <LeftCircleFilled   htmlType="submit" onClick={()=> submitForm(currentStep - 1)} />} */}
                                {currentStep !== 1 && <Button
                                    type="primary"
                                    // htmlType='submit'
                                    shape="circle"
                                    className='prev-next-arrow'
                                    icon={<LeftOutlined />}
                                    onClick={() => submitForm(currentStep - 1)}
                                />
                                }
                                {currentStep === 1 && <div>&nbsp;</div>}

                                <Button
                                    // htmlType="submit"
                                    type="primary"
                                    shape="round"
                                    onClick={() => submitForm(5000)}
                                    loading={isStepUpdating}
                                    disabled={isStepUpdating}
                                    size={"large"}>
                                    {t('common.update')}
                                </Button>
                                {currentStep !== 5 && <Button
                                    type="primary"
                                    // htmlType='submit'
                                    shape="circle"
                                    className='prev-next-arrow'
                                    icon={<RightOutlined />}
                                    onClick={() => submitForm(currentStep + 1)}
                                />}
                                {currentStep === 5 && <div>&nbsp;</div>}

                            </Col>
                        </Row>
                    </Form>
                    {/* } */}
                </Col>
            </Row>

        </div>


    );
};


export default AllStepsWith;
